import React, {Component} from "react";
import Body from "./body";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <main className="wrapper__main">
                <Body />
            </main>
        )
    }
}

export default Main;
