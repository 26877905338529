import React from "react";
import Header from "../main_components/header";
import Main from "../main_components/main";
import Footer from "../main_components/footer";
import SEO from "../main_components/seo";
import "./../css/index.css";
import "../css/main.css";
import "../css/common.css";
import "../css/header.css";
import "../css/body.css";
import "../css/footer.css";

const IndexPage = () => {
    return (
        <>
            <SEO/>
            <div className="wrapper" id="wrapper">
                <Header />
                <Main />
                <Footer />
            </div>
        </>
    )
}

export default IndexPage;