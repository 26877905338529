import React, {Component} from "react";
import { Image } from "../main_components/elements";
import { getText } from "../common/text";

const lang = getText();
class BatataHarraFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className="footer_webring">
                <span className="batata_harra_text">A proud member of "Batata Harra" Webring</span>
                <div className="webring_links">
                    <a title="Visit andoinedebes.com" aria-label="visit Antoine Debes Dot Com" className="text-decoration-none p-1" href="https://antoinedebes.com">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" /></svg>
                    </a>
                    <a rel="noopener" title="Batata Harra Webring" aria-label="visit Batata Harra Dot Guru" className="text-decoration-none m-1" href="https://batataharra.guru" target="_blank">
                        <span className="batata__harra_img__container">
                            <Image className="harra" src={"flame.gif"} title="Batata Harra" alt="Batata Harra" />
                            <span className="batata"><Image src={"batata.webp"} title="Batata" alt="Batata" /></span>
                        </span>
                    </a>
                    <a title="Visit gabykaram.com" aria-label="visit Gaby Karam Dot Com" className="text-decoration-none p-1" href="https://gabykaram.com">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" /></svg>
                    </a>
                </div>
            </div>
        )
    }
}

export default BatataHarraFooter;