const LANG = {
    name: "Georges Dib",
    title: "Full-Stack Developer",
    moto: "Seek discomfort. Burn your comfort zone and fuel your ambitions.",
    who_i_am: "Who I am",
    where_i_worked: {
        title:"Where I worked",
        experiences: [
            {
                comp_name: "Profit Isle",
                timerange: "2021 - Present",
                position: "Senior Software Engineer",
                points: [
                    "Initiated R&D projects",
                    "Designed and implemented entire features",
                    "Split user stories and assigned tasks",
                    "Led technical knowledge meetings",
                    "Acted as technical reference for team mates",
                    "Employed fallback plans for rare unsuccessful deliveries"
                ]
            },
            {
                comp_name: "Profit Isle",
                timerange: "2018 - 2020",
                position: "Junior Software Engineer",
                points: [
                    "Estimated and implemented assigned tasks",
                    "Researched and employed best practices and technologies",
                    "Maximized code coverage by writing unit tests",
                    "Maintained previously written code",
                    "Participated in code reviews as reviewer and reviewee",
                ]
            },
            {
                comp_name: "EMI Offshore",
                timerange: "2017",
                position: "Software Developer Intern",
                points: [
                    "Manually converted pipeline graphics",
                    "Wrote a program to do it for me automatically"
                ]
            }
        ]
    },
    what_i_know: {
        title: "What I know",
        non_technical_exp_heading: "Non-technical skills:",
        technical_exp_heading: "Technical skills:",
        cards: {
            title:{
                front_end: "Front End",
                back_end: "Back End",
                etl_pipelines: "ETL Pipelines",
            },
            body:{
                front_end: "Build enterprise feature-rich applications using multiple front end technologies.",
                back_end: "Design full backend and database sides of data-heavy and fast-response features.",
                etl_pipelines: "Implement data ingestion and load pipelines for big data enterprise projects as well as small scale ones.",
            }
        },
        non_technical_knowledge_points: [
            "Agile Methodology",
            "Team Leadership",
            "Effective Prioritization",
            "Effective Communication",
            "Autonomy",
            "Feature Design"
        ],
        technical_knowledge_points: [
            "JavaScript",
            "ReactJS",
            "Java",
            "Python",
            "BigQuery",
            "PostgreSQL",
            "GCP",
            "Git"
        ]
    },
    what_i_do: {
        title: "What I do"
    },
    summary: "Someone driven by thirst for challenge and an unrelenting curiosity, wandering through multiple technologies and accumulating experience. This continuous journey allowed me to attain considerable levels of proficiency in the domains I worked in. Combined with the soft skills I crafted by working with multiple teams on one hand, and with direct contact with VPs and stakeholders on the other, I am closer today than I was yesterday, to being someone who can make a difference and contribute to positive change on a bigger scale.",
    footer: {
        heading: "This portfolio is only under construction, and so am I!",
        drop_email: "To connect, drop me an email at ",
        email: "george.c.dib@gmail.com",
        checkout_socials: "You can also check out my profiles on:",
        links: [
            {name: "LinkedIn", link: "https://www.linkedin.com/in/georges-dib/"},
            {name: "Github", link: "https://github.com/georges-dib"},
            {name: "LeetCode", link: "https://leetcode.com/georges_dib/"},
            {name: "HackerRank", link: "https://www.hackerrank.com/georges_dib"}
        ]
    }
}

function getText() {
    return LANG;
}

export {getText}