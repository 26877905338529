import React, {Component} from "react";
import { getText } from "../common/text";
import { Image } from "./elements";

const lang = getText();
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <header className="wrapper__header">
                <div className="header__elements-container--center">
                    <div className="profile-picture-wrapper">
                        <Image className="header__picture--middle" src={'header_pic.png'} alt="profile picture"/>
                    </div>

                    <div className="header__text-container--center flex-center-col pos-relative">
                        <h1 id="name_heading">{lang.name}</h1>
                        <h2 id="title_heading">{lang.title}</h2>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;