import * as React from "react";
import "../css/Card.css";
import { Image } from "../main_components/elements";

const Card = React.memo((props)=>{

    return (
        <div className="card">
            <div className="card-header">
                {!!props.iconName &&
                    <div className="icon-container">
                        <Image src={`${props.iconName}.svg`} alt={props.iconName}/>
                    </div>
                }
                <h4>
                    {props.headerContent}
                </h4>
            </div>
            <div className="card-body">
                <p>
                    {props.bodyContent}
                </p>
            </div>
        </div>
    )
});

export default Card;