import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { favicon } from "../images" 

const SEO = ()=>{
        const { site } = useStaticQuery(
            graphql`
                query {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `
        )

        return (
            <Helmet
                htmlAttributes={{
                    lang:"en"
                }}

                title={site?.siteMetadata?.title}
            >
                <link rel="shortcut icon" type="image/png" href={favicon} alt=""/>
                {/* <meta name="icon" href="src/images/iconn.png" /> */}
                {/* <meta name="icon" href={favicon} /> */}
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" integrity="sha512-Fo3rlrZj/k7ujTnHg4CGR2D7kSs0v4LLanw2qksYuRlEzO+tcaEPQogQ0KaoGN26/zrn20ImR1DfuLWnOo7aBA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            </Helmet>
        )
}

export default SEO;