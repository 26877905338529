import * as React from 'react';
import "../css/Timeline.css";

const Timeline = React.memo((props) => {
    return (
        <div className="timeline_container">
                {props.content && props.content.map(ct => {
                    return <div key={ct.comp_name +'_'+ ct.timerange} className="timeline_content">
                        <div className="timeline_content_section timeline_content_left">
                            <h4>{ct.comp_name}</h4>
                            <h4>{ct.timerange}</h4>
                            <h4>{ct.position}</h4>
                        </div>

                        <div className="timeline_divider"></div>
                        <div className="timeline_content_section timeline_content_right">
                            {ct.points && 
                                <ul>
                                    {ct.points.map(pt=>{
                                        return <li key={pt}>{pt}</li>
                                    })}
                                </ul>
                            }
                        </div>
                    </div>
                })}
        </div>
    )
})

export default Timeline;