import React, {Component} from "react";
import { getText } from "../common/text";
import Card from "../components/Card";
import Timeline from "../components/Timeline";
import { Image } from "./elements";

const lang = getText();
class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div id="body">
                <div className="body__moto">{lang.moto}</div>

                <div className="section who_i_am">
                    <h2>{lang.who_i_am}</h2>
                    <p className="no-margin">{lang.summary}</p>
                </div>

                <div className="section where_i_worked">
                    <h2>{lang.where_i_worked.title}</h2>
                    <Timeline content={lang.where_i_worked.experiences} />
                </div>

                <div className="section what_i_do">
                    <h2>{lang.what_i_do.title}</h2>
                    <div className="body__section__experience-card-container">
                        <Card iconName="Embed" headerContent={lang.what_i_know.cards.title.front_end} 
                            bodyContent={lang.what_i_know.cards.body.front_end}/>
                        <Card iconName="Servers" headerContent={lang.what_i_know.cards.title.back_end} 
                            bodyContent={lang.what_i_know.cards.body.back_end}/>
                        <Card iconName="Database" headerContent={lang.what_i_know.cards.title.etl_pipelines}
                            bodyContent={lang.what_i_know.cards.body.etl_pipelines}/>
                    </div>
                </div>

                <div className="section what_i_know">
                    <h2>{lang.what_i_know.title}</h2>
                    <h4>{lang.what_i_know.non_technical_exp_heading}</h4>
                    <ul className="no-margin">
                        {lang.what_i_know.non_technical_knowledge_points.map(pt => {
                            return <li key={pt}>{pt}</li>
                        })}
                    </ul>
                    <h4>{lang.what_i_know.technical_exp_heading}</h4>
                    <div className="body__section__skills-container grid-icon-container no-margin">
                        {lang.what_i_know.technical_knowledge_points.map((pt)=>{
                            return <div key={pt} className="body__skills-container__skills icon-container">
                                <Image src={`${pt}.svg`} title={pt} alt={pt}/>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default Body;