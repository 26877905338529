import React, {Component} from "react";
import { getText } from "../common/text";
import { Image } from "./elements";
import BatataHarraFooter from "../components/BatataHarraFooter";

const lang = getText();
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <footer className="wrapper__footer">
                <div className="wrapper__footer__division">
                    <h2>{lang.footer.heading}</h2>
                    <p>{lang.footer.drop_email}<a href={`mailto:${lang.footer.email}`}>{lang.footer.email}</a></p>
                    <p>{lang.footer.checkout_socials}</p>
                    <div className="grid-icon-container">
                        {lang.footer.links.map(link => {
                            return (
                                <div key={link.name} className="icon-container cursor-pointer">
                                    <a key={link.name} href={link.link} target="_blank" rel="noreferrer">
                                        <Image src={`${link.name}.svg`} title={link.name} alt={link.name}/>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                    <BatataHarraFooter />
                </div>
            </footer>
        )
    }
}

export default Footer;